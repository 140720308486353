/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import "./layout.css"

import Head from "../components/head"

const environment = process.env.NODE_ENV || 'development';

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <div
          style={{
            margin: `0 auto`,
            padding: `0`,
            paddingTop: 0,
            textAlign: `center`,
            background: '#fff'
          }}
        >
          <Head />
          <main>{children}</main>
        </div>
        {environment === 'production' && (
          <>
            <script type='text/javascript' data-cfasync='false' dangerouslySetInnerHTML={{ __html: `window.purechatApi = { l: [], t: [], on: function () { this.l.push(arguments); } }; (function () { var done = false; var script = document.createElement('script'); script.async = true; script.type = 'text/javascript'; script.src = 'https://app.purechat.com/VisitorWidget/WidgetScript'; document.getElementsByTagName('HEAD').item(0).appendChild(script); script.onreadystatechange = script.onload = function (e) { if (!done && (!this.readyState || this.readyState == 'loaded' || this.readyState == 'complete')) { var w = new PCWidget({c: '754be731-2972-4fde-8f6e-00280ac52423', f: true }); done = true; } }; })();`}} />
            <script type='text/javascript' dangerouslySetInnerHTML={{ __html: `window._mfq = window._mfq || [];
        (function() {
            var mf = document.createElement("script");
            mf.type = "text/javascript"; mf.async = true;
            mf.src = "//cdn.mouseflow.com/projects/8dae7d46-9962-4a71-b8cd-53f863225a02.js";
            document.getElementsByTagName("head")[0].appendChild(mf);
        })();`}} />
          </>
        )}

      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
